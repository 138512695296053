<template>
  <v-promised :promise="divisionPromise">
    <template #pending>
      <b-loading :can-cancel="false"></b-loading>
    </template>
    <template v-slot="divisions">
      <section style="background-color: #fff; padding: 2em">
        <div class="is-flex is-justify-content-space-between">
          <h1 class="title is-4 mt-4">
            {{ $t('permissionModal.title') }}
          </h1>
          <div class="is-flex is-flex-direction-column is-align-items-flex-end">
            <figure class="image user-img is-24x24">
              <img class="is-rounded" :src="`${backendUrl}${user.avatar}`" />
            </figure>
            <h3 class="title is-6">
              {{ `${user.first_name} ${user.last_name}` }}
            </h3>
          </div>
        </div>
        <div class="container mt-4">
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t('permissionModal.nameDivisionTable') }}</th>
                <th style="max-width: 100px">
                  <b-checkbox :value="headerCheckbox" @input="assignValueToAllCheckboxes">
                    {{ $t('permissionModal.adminCheckbox') }}
                  </b-checkbox>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="division in divisions" :key="division.id">
                <td>
                  {{ division.name }}
                </td>
                <td>
                  <b-checkbox
                    v-model="currentValueDivisionPermissions[division.id]"
                    :aria-label="`admin${division.id}`"
                    :native-value="division.id"
                  >
                  </b-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="buttons">
            <b-button type="is-primary" @click="submitPermissions">
              {{ $t('views.permissions.submitButton') }}
            </b-button>
            <b-button type="is-beige-light" @click="$emit('close')">
              {{ $t('views.permissions.cancelButton') }}
            </b-button>
          </div>
        </div>
      </section>
    </template>
    <template #rejected="error">
      <v-fail :error="error" />
    </template>
  </v-promised>
</template>

<script>
import { findAllDivisions } from '@/requests/divisions'
import { updateAdminDivisionPermissions, findManagedDivisions } from '@/requests/people'
import Vue from 'vue'
import clone from 'rfdc'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('auth')

export default {
  name: 'permissionModal',
  data() {
    return {
      divisionPromise: null,
      showModal: false,
      headerCheckbox: null,
      currentValueDivisionPermissions: {},
      initialPermissionsValue: {}
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['person']),
    backendUrl() {
      const url = process.env.VUE_APP_MEDIA_URL || 'http://localhost:8001/'
      return url.replace(/\/$/, '')
    }
  },
  methods: {
    async submitPermissions() {
      const editInformation = []
      Object.entries(this.currentValueDivisionPermissions).forEach(([id, value]) => {
        if (this.initialPermissionsValue[id] !== value) {
          editInformation.push({
            id,
            value: this.currentValueDivisionPermissions[id]
          })
        }
      })

      await updateAdminDivisionPermissions({
        userId: this.user.id,
        permissionList: editInformation
      })

      this.$emit('refetchPerson')
      this.$emit('close')
    },

    assignValueToAllCheckboxes(e) {
      this.headerCheckbox = e
      Object.keys(this.currentValueDivisionPermissions).forEach((id) => {
        this.currentValueDivisionPermissions[id] = e
      })
    },

    async init() {
      let divisions
      if (this.person.assign_permissions_to_people) {
        this.divisionPromise = findAllDivisions()
        divisions = await Promise.resolve(this.divisionPromise)
      } else {
        this.divisionPromise = findManagedDivisions()
        divisions = await Promise.resolve(this.divisionPromise)
      }
      divisions.forEach((division) => {
        const hasAdminDivisionPerm =
          this.user.manage_division_information.indexOf(division.id) > -1 &&
          this.user.create_subdivisions.indexOf(division.id) > -1
        Vue.set(this.currentValueDivisionPermissions, division.id, hasAdminDivisionPerm)
      })
      this.initialPermissionsValue = clone()(this.currentValueDivisionPermissions)
    }
  },

  async mounted() {
    await this.init()
  }
}
</script>

<style lang="scss" scoped>
table {
  padding: 0.5em 0.75em;
  width: 100%;
  td {
    border-bottom: 1px solid rgb(220, 211, 211);
  }
  th {
    background: white;
    position: sticky;
    top: 0;
    z-index: 2;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }
}
</style>
