<template>
  <v-promised :promise="memberPromise">
    <template #pending>
      <b-loading :can-cancel="false"></b-loading>
    </template>
    <template v-slot="members">
      <div class="container">
        <h1 class="title is-4 mt-4">
          {{ $t('views.permissions.title') }}
        </h1>

        <div class="container" style="background-color: #fff; padding: 1em">
          <b-table
            :data="members"
            :row-class="(row, index) => !row.has_active_role && 'no-role-background-color'"
          >
            <b-table-column
              field="first_name"
              :label="$t('membersTable.name')"
              sortable
              v-slot="props"
              header-class="table-head-members"
              cell-class="row-class-no-border"
            >
              <router-link
                class="member-link"
                :to="{ name: 'memberProfile', params: { id: props.row.id } }"
              >
                <figure class="image avatar user-img is-24x24 img-overlay-wrap">
                  <img class="is-rounded" :src="`${backendUrl}${props.row.avatar}`" />
                  <svg v-if="!props.row.has_active_role">
                    <circle cx="12" cy="12" r="11" stroke="#0000fc" fill="none" stroke-width="2" />
                    <line
                      x1="4"
                      y1="4"
                      x2="20"
                      y2="20"
                      stroke="#0000fc"
                      fill="none"
                      stroke-width="2"
                    />
                  </svg>
                </figure>
                {{ `${props.row.first_name} ${props.row.last_name}` }}
              </router-link>
            </b-table-column>

            <b-table-column v-slot="props">
              <b-button
                type="is-beige-light"
                @click="
                  () => {
                    modalUser = props.row
                    showModal = true
                  }
                "
              >
                {{ $t('views.permissions.assignButton') }}
              </b-button>
            </b-table-column>

            <b-table-column v-slot="props" v-if="person.assign_permissions_to_people">
              <b-checkbox
                :value="valueManagePeople[props.row.id]"
                :aria-label="`memberManagePerm${props.row.id}`"
                @input="
                  (e) => {
                    addMemberIdIfPermissionChange({
                      objectCheckboxValue: valueManagePeople,
                      changedCheckboxValues: newPermissionsManagePeople,
                      memberId: props.row.id,
                      event: e
                    })
                  }
                "
              >
                {{ $t('views.permissions.managePeopleCheckbox') }}
              </b-checkbox>
            </b-table-column>

            <b-table-column v-slot="props" v-if="person.assign_permissions_to_people">
              <b-checkbox
                :value="valueSeeSensitiveInfo[props.row.id]"
                :aria-label="`memberSensitivePerm${props.row.id}`"
                @input="
                  (e) => {
                    addMemberIdIfPermissionChange({
                      objectCheckboxValue: valueSeeSensitiveInfo,
                      changedCheckboxValues: newPermissionsSensitiveInfo,
                      memberId: props.row.id,
                      event: e
                    })
                  }
                "
                >{{ $t('views.permissions.seeSensitiveInfoCheckbox') }}</b-checkbox
              >
            </b-table-column>

            <b-table-column v-if="person.assign_permissions_to_people" v-slot="props">
              <b-button
                type="is-beige-light"
                @click="
                  () => {
                    modalUser = props.row
                    showDeleteUserModal = true
                  }
                "
              >
                {{ $t('views.permissions.deleteButton') }}
              </b-button>
            </b-table-column>

            <b-table-column v-if="person.assign_permissions_to_people" v-slot="props">
              <a class="is-flex" @click="showRoleModal = true" v-if="!props.row.has_active_role">
                <b-icon icon="alert-decagram" type="is-primary"></b-icon>
                {{ $t('views.permissions.noRole') }}
              </a>
            </b-table-column>
          </b-table>

          <b-modal :width="size" v-model="showModal" v-slot="props">
            <permission-modal
              :user="modalUser"
              @close="props.close"
              @refetchPerson="refetchPeople"
            />
          </b-modal>

          <b-modal v-model="showRoleModal" width="350px">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-left">
                    <b-icon icon="information-outline" size="is-large" type="is-azul-imfd" />
                  </div>

                  <div class="media-content">
                    <h3 class="title is-3">{{ $t('views.permissions.noRoleTitle') }}</h3>
                    <p>
                      <strong>{{ $t('views.permissions.modalMessageSubtitle') }}</strong>
                      {{ $t('views.permissions.modalMessage') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </b-modal>

          <b-modal v-model="showDeleteUserModal" width="350px" v-slot="props">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-left">
                    <b-icon icon="information-outline" size="is-large" type="is-azul-imfd" />
                  </div>

                  <div class="media-content">
                    <h3 class="title is-3">{{ $t('views.permissions.modalTitleDeleteUser') }}</h3>
                    <p>
                      <strong>{{ $t('views.permissions.modalMessageDeleteQuestion') }}</strong>
                      {{ $t('views.permissions.modalMessageDeleteUser') }}
                    </p>
                    <div class="buttons mt-4 end-buttons">
                      <b-button type="is-beige-light" @click="props.close">
                        {{ $t('views.permissions.modalDeleteUserBackbutton') }}
                      </b-button>
                      <b-button
                        type="is-primary"
                        @click="
                          () => {
                            deleteUser()
                            props.close()
                          }
                        "
                        aria-label="deleteConfirm"
                      >
                        {{ $t('views.permissions.modalDeleteUserConfirmBotton') }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-modal>

          <div class="mb-4" style="padding: 2em">
            <div class="buttons is-right mt-4 end-buttons table-border">
              <b-button type="is-primary" @click="submitPermissions">{{
                $t('views.permissions.submitButton')
              }}</b-button>
              <b-button type="is-beige-light" @click="$router.go(-1)">
                {{ $t('views.permissions.cancelButton') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #rejected="error">
      <v-fail :error="error" />
    </template>
  </v-promised>
</template>

<script>
import { toFrontendShowDateFormat } from '@/utils/date-utils'
import { createNamespacedHelpers } from 'vuex'
import {
  fetchPeopleWithPermissions,
  assignManagePeoplePermission,
  assignSeeSensitiveInfoPermission,
  deleteManagePeoplePermission,
  deleteSeeSensitiveInfoPermission,
  deactivateUser
} from '@/requests/people'
import PermissionModal from '@/components/PermissionModal.vue'
import AppError from '@/errors/AppError'
import Vue from 'vue'

const { mapState } = createNamespacedHelpers('auth')

export default {
  components: {
    PermissionModal
  },
  name: 'Permissions',
  data() {
    return {
      memberPromise: null,
      showModal: false,
      modalUser: null,
      showRoleModal: false,
      showDeleteUserModal: false,
      size: window.innerWidth * 0.8,
      valueManagePeople: {},
      valueSeeSensitiveInfo: {},
      newPermissionsManagePeople: [],
      newPermissionsSensitiveInfo: []
    }
  },
  computed: {
    ...mapState(['person']),
    backendUrl() {
      const url = process.env.VUE_APP_MEDIA_URL || 'http://localhost:8001/'
      return url.replace(/\/$/, '')
    }
  },
  methods: {
    toFrontendShowDateFormat,
    addMemberIdIfPermissionChange({ objectCheckboxValue, changedCheckboxValues, memberId, event }) {
      /*
      This is a very very complex implentation for just check boxes. This was made this way beacause we just want
      to have the changed values of the checkbox and also have the inital value marked of people who had permissions.

      objectCheckboxValue: is an object that store if a user has the related permission (true or false)
      changedCheckBoxValues: an array that store the person Id if the user has check any of their box
      memberId: id of the person related to the checkbox
      event: value of the checkbox (true o false)
      */
      Vue.set(objectCheckboxValue, memberId, event)
      if (changedCheckboxValues.includes(memberId)) {
        changedCheckboxValues.splice(changedCheckboxValues.indexOf(memberId), 1)
      } else {
        changedCheckboxValues.push(memberId)
      }
    },
    async submitPermissions() {
      try {
        await Promise.all([
          this.newPermissionsManagePeople.map((id) => {
            if (this.valueManagePeople[id]) {
              return assignManagePeoplePermission({ userId: id })
            } else {
              return deleteManagePeoplePermission({ userId: id })
            }
          }),
          this.newPermissionsSensitiveInfo.map((id) => {
            if (this.valueSeeSensitiveInfo[id]) {
              return assignSeeSensitiveInfoPermission({ userId: id })
            } else {
              return deleteSeeSensitiveInfoPermission({ userId: id })
            }
          })
        ])
        this.$buefy.toast.open({
          message: this.$t('views.permissions.successMessage'),
          type: 'is-success',
          position: 'is-top'
        })
      } catch (err) {
        throw new AppError()
      }
    },
    async refetchPeople() {
      this.memberPromise = fetchPeopleWithPermissions()
      const members = await Promise.resolve(this.memberPromise)
      members.forEach((member) => {
        Vue.set(this.valueManagePeople, member.id, member.assign_permissions_to_people)
        Vue.set(this.valueSeeSensitiveInfo, member.id, member.see_sensitive_information)
      })
    },
    async deleteUser() {
      try {
        await deactivateUser({ userId: this.modalUser.id })
        this.$buefy.toast.open({
          message: this.$t('views.permissions.successDeleteMessage'),
          type: 'is-success',
          position: 'is-top'
        })
      } catch (err) {
        throw new AppError()
      }
    }
  },
  async mounted() {
    this.memberPromise = fetchPeopleWithPermissions()
    const members = await Promise.resolve(this.memberPromise)
    members.forEach((member) => {
      Vue.set(this.valueManagePeople, member.id, member.assign_permissions_to_people)
      Vue.set(this.valueSeeSensitiveInfo, member.id, member.see_sensitive_information)
    })
  }
}
</script>

<style scoped>
.end-buttons {
  padding: 8px 12px;
  margin-top: 2em;
  margin-right: 0.5em;
}
.table-border {
  border-top-style: solid;
  border-width: 0.1em;
  border-color: rgb(235, 229, 229);
}

.img-overlay-wrap svg {
  position: absolute;
  top: 0;
  left: 0;
}
</style>

<style>
.no-role-background-color {
  background: #02fbff !important;
  color: #fff !important;
}
</style>
