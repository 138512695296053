<template>
  <div>
    <v-form
      :on-submit="sendForm"
      :on-submit-success="onSuccess"
      :zod-schema-validator="formSchema"
      :on-submit-error="submitError"
      ref="signUpForm"
    >
      <template v-slot="{ form }">
        <message-success v-if="showSuccess" :detail="$t('signUpForm.successMsg')" />
        <message-error v-if="form.errors.length > 0" :detail="Object.values(form.errors).flat()" />

        <div class="is-flex is-justify-content-space-around" data-testid="email" id="email-div">
          <div class="flex-form">
            <b-field
              :label="$t('resetPassword.email')"
              id="email-input"
              :message="form.errors.email"
              :type="form.types.email"
            >
              <b-input v-model="form.values.email" aria-label="email" />
            </b-field>
          </div>
        </div>

        <div
          class="is-flex is-justify-content-space-around"
          data-testid="password"
          id="password-div"
        >
          <div class="flex-form">
            <b-field
              :label="$t('resetPassword.password')"
              :message="form.errors.new_password1"
              :type="form.types.new_password1"
              label-for="password1"
            >
              <b-input
                type="password"
                id="password1"
                v-model="form.values.new_password1"
                password-reveal
              />
            </b-field>
          </div>
        </div>

        <div class="is-flex is-justify-content-space-around" data-testid="password-verify">
          <div class="flex-form">
            <b-field
              :label="$t('resetPassword.passwordConfirm')"
              :message="form.errors.new_password2 || form.errors.__root__"
              :type="form.types.new_password2"
              label-for="password2"
            >
              <b-input
                type="password"
                id="password2"
                v-model="form.values.new_password2"
                password-reveal
              />
            </b-field>
          </div>
        </div>

        <div class="is-flex is-justify-content-right">
          <div>
            <b-button
              class="mt-4"
              native-type="submit"
              type="is-azul-imfd"
              expanded
              id="send-button"
              >{{ $t('resetPassword.button') }}</b-button
            >
          </div>
        </div>
      </template>
    </v-form>
  </div>
</template>

<script>
import MessageSuccess from '@/components/MessageSuccess'
import MessageError from '@/components/MessageError'
import VForm from '@/components/VForm.vue'
import { passwordRecovery } from '@/requests/people'
import { z } from 'zod'

export default {
  name: 'SignUpForm',
  components: {
    MessageSuccess,
    MessageError,
    VForm
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showSuccess: false,
      formSchema: z.object({
        email: z.string(),
        new_password1: z.string(),
        new_password2: z.string()
      })
    }
  },
  methods: {
    async sendForm(form) {
      await passwordRecovery({ token: this.token, data: form })
    },
    onSuccess() {
      this.showSuccess = true
      setTimeout(() => {
        this.$router.push({ name: 'profile' })
      }, 1500)
    },
    submitError(err) {
      this.$buefy.toast.open({
        message: err.message || this.$i18n.t('messageError.somethingWrong'),
        type: 'is-danger'
      })
    }
  }
}
</script>

<style scoped>
.flex-form {
  flex-basis: 40%;
}
</style>
