<template>
  <div class="signup-page is-flex">
    <div
      class="login-background"
      :style="{
        'background-image': `url(${require('@/assets/images/patron_imfd.png')})`
      }"
    >
      <div class="card is-flex is-flex-direction-column">
        <div class="card-image logo">
          <img src="@/assets/logo_imfd_2.png" :alt="$t('navbar.logoAlt')" />
        </div>
        <h1 class="title is-3 is-align-self-center">{{ $t('resetPassword.title') }}</h1>
        <reset-password-form :token="$route.params.token" />
      </div>
    </div>
  </div>
</template>

<script>
import AppError from '@/errors/AppError'
import ResetPasswordForm from '@/components/ResetPasswordForm.vue'

export default {
  components: { ResetPasswordForm },
  name: 'SignUp',
  data() {
    return {
      loading: true,
      email: null,
      activeInvitation: null
    }
  },
  methods: {
    isControlledError(error) {
      return error instanceof AppError
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/stylesheets/imfd/colors.scss';
.is-full-height {
  height: 100vh;
}
.signup-page {
  height: 100vh;

  .title {
    margin-top: 5vh;
  }

  .logo {
    width: 20%;
  }

  .login-background {
    background-size: contain;
    background-origin: box;
    height: 100vh;
    /*
    unfortunately background images cannot be ran through the asset bundler. bummer!
    background-image: url(@/assets/images/patron_imfd.png);
     */
  }

  .card {
    background-color: white;
    margin-top: 5vh;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    padding: 5vh;

    .card-content {
      margin-top: 20vh;
    }
  }

  > div {
    flex: 1;
  }
}
</style>
