import axios from './index'
import FormValidationError from '@/errors/FormValidationError'
import AppError from '@/errors/AppError'

export async function acceptInvitation({ token, data }) {
  const response = await axios.post(`/api/invitations/${token}/accept`, data)
  if (response.status === 204) {
    return response
  } else if (response.status === 422) {
    throw new FormValidationError(response.data)
  }

  throw new Error('Unexpected status code returned')
}

export async function checkInvitation(invitationToken) {
  const requestUrl = `api/invitations/${invitationToken}/check`
  const response = await axios.get(requestUrl)

  if (response.status === 200) {
    return response.data
  } else if (response.status === 410 || response.status === 404) {
    throw new AppError(response.data.detail)
  }
  throw new Error('Unexpected status code returned')
}
