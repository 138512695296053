<template>
  <v-promised :promise="activeInvitation">
    <template #pending>
      <b-loading :can-cancel="false"></b-loading>
    </template>

    <template>
      <div class="signup-page is-flex">
        <div
          class="signup-background columns is-vcentered"
          :style="{
            'background-image': `url(${require('@/assets/images/patrones_bg_imfd.png')})`
          }"
        >
          <div class="column is-2-desktop is-1-tablet"></div>
          <div class="column is-8-desktop is-10-tablet is-12-mobile is-centered">
            <div class="card">
              <div class="card-image logo">
                <img src="@/assets/logo_imfd_2.png" :alt="$t('navbar.logoAlt')" />
              </div>
              <h1 class="title is-3">{{ $t('views.signup.registerTitle') }}</h1>
              <sign-up-form :token="$route.params.token" />
            </div>
          </div>
          <div class="column is-2-desktop is-1-tablet"></div>
        </div>
      </div>
    </template>

    <template #rejected="error">
      <div
        class="
          container
          has-text-centered
          is-full-height is-justify-content-center is-align-items-center is-flex is-flex-mobile
        "
        v-if="isControlledError(error)"
      >
        <b-icon
          icon="close-outline"
          type="is-danger"
          size="100px"
          custom-size="icon-size"
          custom-class="icon-size"
        />
        <h3 class="subtitle is-4">{{ error.message }}</h3>
      </div>
      <v-fail v-else :error="error" />
    </template>
  </v-promised>
</template>

<script>
import { checkInvitation } from '@/requests/invitations'
import AppError from '@/errors/AppError'
import SignUpForm from '@/components/SignUpForm.vue'

export default {
  components: { SignUpForm },
  name: 'SignUp',
  data() {
    return {
      loading: true,
      email: null,
      activeInvitation: null
    }
  },
  methods: {
    isControlledError(error) {
      return error instanceof AppError
    }
  },
  mounted() {
    this.activeInvitation = checkInvitation(this.$route.params.token)
  }
}
</script>

<style scoped lang="scss">
@import '../assets/stylesheets/imfd/colors.scss';
.is-full-height {
  height: 100vh;
}
.signup-page {
  height: 100vh;

  .title {
    margin-top: 5vh;
  }

  .logo {
    width: 200px;
  }

  .signup-container {
    height: 100vh;
  }

  .signup-background {
    background-size: inherit;
    background-origin: box;
    background-repeat: repeat-y;
    background-position: right;
    height: 100vh;
    /*
    unfortunately background images cannot be ran through the asset bundler. bummer!
    background-image: url(@/assets/images/patron_imfd.png);
     */
  }

  .card {
    background-color: white;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    padding: 5vh;

    .card-content {
      margin-top: 20vh;
    }
  }

  > div {
    flex: 1;
  }
}
</style>
