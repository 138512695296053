<template>
  <div>
    <v-form :onSubmit="sendForm" :onSubmitSuccess="onSuccess" ref="signUpForm">
      <template v-slot="{ form }">
        <message-success v-if="showSuccess" :detail="$t('signUpForm.successMsg')" />
        <message-error v-if="form.errors.length > 0" :detail="Object.values(form.errors).flat()" />
        <message-error v-if="form.errors.__root__" :detail="form.errors.__root__" />

        <div class="columns is-tablet">
          <div class="column">
            <b-field
              :label="$t('signUpForm.firstNameLabel')"
              id="first-name-input"
              :message="form.errors.first_name"
              :type="form.types.first_name"
            >
              <b-input v-model="form.values.first_name" />
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('signUpForm.lastNameLabel')"
              id="last-name-input"
              :message="form.errors.last_name"
              :type="form.types.last_name"
            >
              <b-input v-model="form.values.last_name" />
            </b-field>
          </div>
        </div>

        <div class="columns is-tablet">
          <div class="column">
            <b-field
              :label="$t('signUpForm.idNumberLabel')"
              id="id-number-input"
              :message="form.errors.id_number"
              :type="form.types.id_number"
            >
              <b-input v-model="form.values.id_number" />
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('signUpForm.nationalityLabel')"
              id="nationality-select"
              :message="form.errors.nationality"
              :type="form.types.nationality"
            >
              <country-selector v-model="form.values.nationality" />
            </b-field>
          </div>
        </div>

        <div class="columns is-tablet">
          <div class="column">
            <b-field
              :label="$t('signUpForm.passwordLabel')"
              id="password-input"
              :message="form.errors.password"
              :type="form.types.password"
            >
              <b-input type="password" v-model="form.values.password" password-reveal />
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('signUpForm.passwordVerificationLabel')"
              id="password-verification-input"
              :message="form.errors.password_verification"
              :type="form.types.password_verification"
            >
              <b-input
                type="password"
                v-model="form.values.password_verification"
                password-reveal
              />
            </b-field>
          </div>
        </div>
        <div class="is-flex is-justify-content-right">
          <div>
            <b-button
              class="mt-4"
              native-type="submit"
              type="is-azul-imfd"
              expanded
              id="send-button"
              >{{ $t('signUpForm.button') }}</b-button
            >
          </div>
        </div>
      </template>
    </v-form>
  </div>
</template>

<script>
import MessageSuccess from '@/components/MessageSuccess'
import MessageError from '@/components/MessageError'
import CountrySelector from '@/components/CountrySelector'
import VForm from '@/components/VForm.vue'
import { acceptInvitation } from '@/requests/invitations'

export default {
  name: 'SignUpForm',
  components: {
    MessageSuccess,
    MessageError,
    CountrySelector,
    VForm
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showSuccess: false
    }
  },
  methods: {
    async sendForm(form) {
      let loadingComponent = this.$buefy.loading.open({
        container: this.$refs.signUpForm.$el
      })

      try {
        await acceptInvitation({ token: this.token, data: form })
      } finally {
        loadingComponent.close()
      }
    },
    onSuccess() {
      this.showSuccess = true
      setTimeout(() => {
        this.$router.push({ name: 'profile' })
      }, 1500)
    }
  }
}
</script>

<style scoped>
.flex-form {
  flex-basis: 40%;
}
</style>
